<template>
    <div class="aa-card-equipo">
        <div class="aa-card-equipo-header">
            <div class="equipo-avatar">
                <img :src="personal.avatar" />
            </div>
            <div class="aa-card-nombre text-start px-1 mb-1">
                <span class="d-block"> {{personal.puesto}}</span>
                <span class="d-block mt-1 fw-600"> {{personal.persona.nombre}}</span>
            </div>
        </div>
        <div class="aa-card-equipo-datos text-start">
            <span class="d-block">{{personal.persona.email}}</span>
            <span class="d-block">{{personal.persona.telefono}}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        datos: {
            type: Object,
            required: true
        }
    },
    setup(props){
        return {
            personal: props.datos
        }
    }

}
</script>

<style lang="sass" scoped>

.aa-card-equipo
    $root:&
    display: flex
    flex-direction: column
    &-header
        display: flex
        flex-direction: row
        min-height: 120px
        .equipo-avatar
            width: 100px
            img
                width: 90px
                border-radius: 14px
                margin: 5px 0px 0px 5px

        .aa-card-nombre
            align-self: center
            span
                display: block
    &-datos
        background-color: $base-color
        color: #ffffff
        position: relative
        top: -11px
        z-index: -2
        padding: 1rem
        font-size: 1rem
        border-radius: 2px
        line-height: 200%
        box-shadow: $green 0px -7px 0px 0px
        height: 100px
</style>